
.rainbow-button-wrapper-ions {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* 虹色の背景を持つ疑似要素 */
.rainbow-button-wrapper-ions::before {
  content: '';
  position: absolute;
  inset: 0; /* top: 0, right: 0, bottom: 0, left: 0 の省略形 */
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    #ff6b6b 0deg,
    #ffd93d 90deg,
    #6bcb77 180deg,
    #4d96ff 270deg,
    #ff6b6b 360deg
  );
  padding: 1.5px; /* これが境界線の太さになります */
}

/* 白い背景を持つ内側の要素 */
.rainbow-button-wrapper-ions::after {
  content: '';
  position: absolute;
  inset: 1.5px; /* border の太さ分だけ内側に */
  background: white;
  border-radius: 50%;
  z-index: 0;
}