.body-selected-text-ions {
  padding: 4px 10px;
  color:rgb(86, 85, 85);
  flex: 1;
  width: 100%;
  border-radius: 8px;
}
.body-selected-text-ions:hover {
  background-color: rgb(238, 238, 238);
}
.body-selected-text-ions.selected {
  background-color: rgb(236, 236, 236);
}
