.comment-card-kubo-molecules-with-reply{
  display: flex;
  gap: 25px;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.comment-card-more-button{
  background-color: #f7a2a4;
  color: white;
  font-weight: 600;
}