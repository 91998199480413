.mini-notify-card-grid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.mini-notify-card-grid-container{  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
