.idea-comments-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 50px;
}

.idea-comments-form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
}

.idea-comments-form-top{
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 15px;
}
.idea-comments-form-top.sp{
  gap: 10px;
}

.idea-comments-cell{
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.idea-comments-cards-container{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 35px;
  align-items: flex-start;
  width: 100%;
}