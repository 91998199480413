
.loading-full-spinner{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 300px;
  background-color: #F6F8FA;
  min-height: 95vh;
}
