.spinner-atoms {
  width: 36px;
  height: 36px;
  border: 4px solid #ffffff;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}

.spinner-atoms.white{
  border: 4px solid #ffffff;
  border-top-color: transparent;
}
.spinner-atoms.grey{
  border: 4px solid  rgb(220, 220, 220) ;
  border-top-color: transparent;
}
.spinner-atoms.black{
  border: 4px solid #7e7e7e;
  border-top-color: transparent;
}

.spinner-atoms.small{
  width: 24px;
  height: 24px;
}
.spinner-atoms.medium{
  width: 36px;
  height: 36px;
}
.spinner-atoms.large{
  width: 48px;
  height: 48px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}