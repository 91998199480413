
.request-details{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  padding: 40px 5px;
  gap: 40px;
}
.request-details.sp{
  gap: 30px;
}

.request-details-cell{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}


.request-details-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin: 0;
}