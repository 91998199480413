.img-logo-ions {
  object-fit: cover;  
  transition: width 0.1s ease, height 0.1s ease, ; /* スムーズなアニメーションのため */
}

.img-logo-ions.clickable {
  cursor: pointer;
}

.img-logo-ions.small {
  height: 30px;
  box-sizing: border-box;  
}
.img-logo-ions.medium {
  height: 36px;
  box-sizing: border-box;  
}
.img-logo-ions.large {
  height: 65px;
  box-sizing: border-box;  
}