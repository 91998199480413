
.mini-notify-card-container {
  width: 100%;
  align-items: center;
  display: flex;
  padding: 10px 5px;
  gap: 5px;
  border-bottom: 1px solid #cccccc;
  cursor: pointer;
}
.mini-notify-card-container:hover {
  background-color: rgb(245, 245, 245);
  transition: background-color 0.3s ease ;
}

.mini-notify-card-container.read{
  background-color: rgb(234, 234, 234);
}

.mini-notify-card-titles {
  display: flex;
  gap: 6px;
  flex-direction: column;
  pointer-events: none;
  overflow-x: hidden;
  align-items: flex-start;
}

.mini-notify-card-text{
  max-width: 100%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.mini-notify-card-title{
  color: white;
  padding: 0px 15px;
  border-radius: 5px;
}
.mini-notify-card-title.chat\.unread{
  background-color: #3a9ed8;
}
.mini-notify-card-title.idea\.apply-request{
  background-color: #38864f;
}
.mini-notify-card-title.idea\.applied{
  background-color: #383E86;
}
.mini-notify-card-title.idea\.comment{
  background-color: #dc6d6d;
}
.mini-notify-card-title.idea\.comment-reply{
  background-color: #dc6da6;
}