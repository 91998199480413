.request-card2-img {
  width: 100%;
  aspect-ratio: 7/4.5;
}

.request-card2-texts {
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}