.idea-user-and-returns-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.idea-user-and-returns-cell{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.idea-user-cell{
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #F6F8FA;
  /* background-color: transparent; */
  padding: 10px 15px;
  border-radius: 5px;

  border: 0.5px solid white;
}

.idea-return-cell{
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #F6F8FA;
  /* background-color: transparent; */
  padding: 10px 15px;
  border-radius: 5px;

  border: 0.5px solid white;
}