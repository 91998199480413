/* アイデアテンプレートのスタイル */
.idea-template{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F8FA;
}

/* アイデアテンプレート全体のスタイル */
.idea-template-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  gap: 20px;
  padding-bottom: 300px;
}

/* アイデアテンプレートの上部のスタイル */
.idea-template-top{
  width: 100%;
}

/* アイデアテンプレートの下部のスタイル */
.idea-template-bottom{
  display: flex;
  flex-direction: row;
  align-items: space-between;
  padding-top: 10px;
  padding-bottom: 200px;
  gap: 40px;
}

.idea-template-bottom-left{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.idea-template-bottom-right{
  width: 38%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* アイデアテンプレートの下部のスタイル */
.idea-template-bottom.sp{
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding-top: 10px;
  gap: 70px;
}

.idea-template-bottom-cells.sp{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.idea-activity-template-titles{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}