.notify-detail-card-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.notify-detail-card-nav-buttons{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.notify-detail-card-titles {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start; 
  width: 100%;
}

.notify-card-date{
  pointer-events: none;
  width: 15%;
}