.textfield-form{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.textfield-form-row{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.user-info-form-buttons{
  margin: 20px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}