.idea-delete-confirm-popup {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
}
.idea-delete-confirm-popup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}
.idea-delete-confirm-popup-warning {
  color: #666;
  font-size: 14px;
  padding: 16px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.idea-delete-confirm-popup-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
}