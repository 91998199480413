.selected-tab-button-ions{
  border-radius: 0px;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.selected-tab-button-ions.selected{
  border: none;
  border-top: 1px solid #383E86;
  background-color: white;
  color: #363636;
}

.selected-white-round-button-ions {
  background-color: white;
  border: 0.5px solid lightgrey;
  font-weight: bold;
  color: grey;
}

.selected-white-round-button-ions.selected {
  background: linear-gradient(to right, #89EAD8, #3A85DC);
  color: white;
}