.selected-page-button-ions{
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: snow;
  color: grey;
  border: none;
}

.selected-page-button-ions.selected{
  background-color: #383E86;
  color: white;
}

.selected-page-button-ions.small {
  padding: 0px;
  width: 35px;
  height: 35px;
  font-size: 18px;
}

.selected-page-button-ions.normal {
  padding: 0px;
  width: 40px;
  height: 40px;
  font-size: 20px;
}

.selected-page-button-ions.medium {
  padding: 0px;
  width: 45px;
  height: 45px;
  font-size: 22px;
}

