.request-carousel-item{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
  cursor: pointer;
  pointer-events: all;
}

.request-carousel-button{
  background-color: #40B2D3;
  border: 2.5px solid white;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
}
.request-carousel-button.prev{
  position: absolute;
  top: 36%;
  left: 10px;
}
.request-carousel-button.next{
  position: absolute;
  top: 36%;
  right: 10px;
}

.request-carousel-button-text{
  font-size: 20px;
  font-weight: 700;
  font-family: Noto Sans JP;
  color: white;
  pointer-events: none;
}