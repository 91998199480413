.idea-calousel-with-home-title{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.idea-calousel-with-title{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.idea-calousel-title-more{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.idea-calousel-bg-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-bottom: 80px;
}

.idea-calousel-button{
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  justify-content: center;
}

.idea-calousel-bg{
  position: absolute;
  width: 100%;
  height: 70%;
  bottom: 0;
  z-index: -1;
  background: linear-gradient(to right bottom, rgba(163, 127, 209, 0.25), rgba(254, 89, 91, 0.25));
}

.sakairi-idea-carousel-more-button{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
  margin-top: 20px;
  gap: 10px;
  border: none;
  background-color: transparent;
  z-index: 2;
}

.sakairi-idea-carousel-text{
  z-index: 2;
  cursor: pointer;
}