
.idea-detail-and-cf-wrapper-sp{
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F8FA;
  /* background-color: transparent; */
  gap: 30px;
}

.idea-detail-and-cf-wrapper-sp-private{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.idea-wrapper-sp-buttons{
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.idea-detail-and-cf-wrapper-sp-private-buttons{
  display: flex;
  gap: 5px;
}

.idea-wrapper-sp-top{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding-bottom: 10px;
}

.idea-wrapper-sp-title{
  font-weight: bold !important;
}

.idea-wrapper-sp-top-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.idea-img{
  width: 100%;
  border-radius: 15px;
}

/* 右側 cf詳細のプログレスバー全体 */
.idea-wrapper-sp-top-progress{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  background-color: white;
  /* background-color: transparent; */
  padding: 20px 20px;
  border-radius: 5px;
}

/* 右側 cf詳細のプログレスバーの上部 出資総額と出資者総人数 */
.idea-wrapper-sp-top-progress-info-backer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 右側 cf詳細のプログレスバーの上部 %と残り日数 */
.idea-wrapper-sp-top-right-progress-info-days{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 中部にあるタブの集合 */
.idea-wrapper-sp-bottom-tabs{
  width: 100%;
  display: flex;
  align-items: center;
}