
.idea-detail-and-cf-wrapper-pc{
  padding-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F8FA;
  gap: 20px;
}

.idea-detail-and-cf-wrapper-pc-private{
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.idea-detail-and-cf-wrapper-pc-private-text{
  background-image: linear-gradient(
    to bottom,
    transparent 80%,
    rgb(171, 231, 255) 20%
  );
}
.idea-detail-and-cf-wrapper-pc-private-buttons{
  display: flex;
  gap: 5px;
}

.idea-wrapper-pc-top{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding-bottom: 20px;
}

.idea-wrapper-pc-top-left{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.idea-img{
  width: 100%;
  border-radius: 15px;
}

.idea-wrapper-pc-top-right{
  width: 39%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.idea-wrapper-pc-top-right-subtitle{
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 20px;
}

/* 右側 cf詳細のプログレスバー全体 */
.idea-wrapper-pc-top-right-progress{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* 右側 cf詳細のプログレスバーの上部 出資総額と出資者総人数 */
.idea-wrapper-pc-top-right-progress-info-backer{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* 右側 cf詳細のプログレスバーの上部 %と残り日数 */
.idea-wrapper-pc-top-right-progress-info-days{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 右側 cf詳細の「出資する」ボタンなどの集合 */
.idea-wrapper-pc-top-right-buttons{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

/* 右側 cf詳細の「コメント」や「いいね」などのサブボタン */
.idea-wrapper-pc-top-right-buttons-sub{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

/* 中部にあるタブの集合 */
.idea-wrapper-pc-bottom-tabs{
  width: 100%;
  display: flex;
  align-items: center;
}
