.notify-popover-wrapper{
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.notify-popover-empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
}