.popover-wrapper {
  position: relative;
  display: inline-block;
}

.popover-atoms {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: absolute;
  opacity: 0;
  animation: fadeInPopover 0.2s ease 0.1s forwards;
  z-index: 1000;
  width: max-content;
}

@keyframes fadeInPopover {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popover-atoms.closing {
  opacity: 1;
  animation: fadeOutPopover 0.2s ease 0.1s forwards;
}

@keyframes fadeOutPopover {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.popover-top {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}
.popover-bottom {
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
}
.popover-left {
  right: 100%;
  top: 50%;
  transform: translate(0, -50%);
}
.popover-right {
  left: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

.popover-top-left {
  bottom: 100%;
  left: 0;
  transform: translate(0, 0);
}
.popover-top-left-center {
  bottom: 100%;
  left: 0;
  transform: translate(-25%, 0);
}
.popover-top-right {
  bottom: 100%;
  right: 0;
  transform: translate(0, 0);
}
.popover-top-right-center {
  bottom: 100%;
  right: 0;
  transform: translate(25%, 0);
}

.popover-bottom-left {
  top: 100%;
  left: 0;
  transform: translate(0, 0);
}
.popover-bottom-left-center {
  top: 100%;
  left: 0;
  transform: translate(-25%, 0);
}
.popover-bottom-right {
  top: 100%;
  right: 0;
  transform: translate(0, 0);
}
.popover-bottom-right-center {
  top: 100%;
  right: 0;
  transform: translate(25%, 0);
}
.popover-left-top {
  right: 100%;
  top: 0;
  transform: translate(-25%, 0);
}
.popover-left-bottom {
  right: 100%;
  bottom: 0;
  transform: translate(0, 0);
}
.popover-right-top {
  left: 100%;
  top: 0;
  transform: translate(0, 0);
}
.popover-right-bottom {
  left: 100%;
  bottom: 0;
  transform: translate(0, 0);
}
