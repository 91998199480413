
.contact-template-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  background-color: #f5f5f5;
  min-height: 140vh;
}

.contact-template-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  top: 45px;
  z-index: 2;
}

.contact-template-header-title {
  color: #383E86;
  font-size: 36px;
  font-family: "Montserrat", sans-serif;
}

.contact-template-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  padding-top: 100px;
  position: relative;
  background-color: white;
  padding-bottom: 50px;
}
.contact-template-main.pc {
  width: 600px
}

.contact-template-main-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-template-main-top-nav{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
}

.theme-nav-step-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 115px;
}
.theme-nav-step-container.with-before{
  align-items: flex-end;
}
.theme-nav-step-container.with-after{
  align-items: flex-start;
}

.theme-nav-step-container.with-after.with-before{
  width: 150px;
  align-items: center;
  text-align: center;
}

.theme-nav-step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  padding: 10px;
  border: 1px solid #383E86;
  border-radius: 50%;
}

.theme-nav-step.with-after::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%; 
  transform: translateY(-50%);
  height: 2px;
  background-color: #383E86;
  width: 36px;
}
.theme-nav-step.with-before::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%; 
  transform: translateY(-50%);
  height: 2px;
  background-color: #383E86;
  width: 36px;
}

.theme-nav-step-text{
  text-align: center;
}

.theme-nav-step.disabled {
  border-color: #D1D5DB;  /* 境界線を薄いグレーに */
}

.theme-nav-step.disabled::after {
  height: 1px;
  background-color: #D1D5DB;  /* 線も薄いグレーに */
}
.theme-nav-step.disabled::before {
  height: 1px;
  background-color: #D1D5DB;  /* 線も薄いグレーに */
}

.contact-template-main-top-description{
  display: flex;
  width: 80%;
  text-align: center;
  line-height: 1.8;
}

.contact-template-main-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  gap: 20px;
}

.contact-template-main-form-cell{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}

.contact-template-popup{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: '20px 30px';
}

.contact-template-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
}



.contact-success-template-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 95vh;
  gap: 10px;
  padding-top: 30px;
  
}

.contact-success-template-main.sp{
  width: 95%;
}

.contact-success-template-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  width: 95%;
  margin: 0;
}

.contact-success-template-list li {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 15px;
}
