.room-card-molecules-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: whitesmoke; */
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid rgb(240, 240, 240);
  padding: 20px 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* スムーズな拡大アニメーション */
}

.room-card-molecules-container:hover{
  background-color: rgb(249, 249, 249);
  box-shadow: 0 1px 2px rgba(97, 97, 97, 0.05);
  transform: scale(1.005);
}

.room-card-icons-and-name{
  pointer-events: none;
  display: flex;
  align-items: center;
  gap: 15px;
  max-width: 80%;
}

.room-card-user-name-message{
  display: flex;
  flex-direction: column;
  gap: 2px;
  max-width: 80%;
}

.room-card-time-unread{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 5px;
  pointer-events: none;
}
