/* 出資テンプレートのスタイル */
.idea-invest-complete-template{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background-color: #F6F8FA;
}

/* 出資テンプレートのタイトルヘッダー */
.idea-invest-complete-template-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.idea-invest-complete-template-main-heading{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 10px 0px 10px 15px;
  border-left: 5px solid #383E86;
  border-bottom: 1px solid lightgrey;
}

/* 出資テンプレートのタイトルヘッダーのタイトル */
.idea-invest-complete-template-header-title{
  font-size: 32px;
}

.idea-invest-complete-template-main-heading-title{
  font-size: 16px;
  color: #6f76c3;
  font-weight: 400;
}


/* 出資テンプレート全体のスタイル */
.idea-invest-complete-template-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
}

/* 出資テンプレートのアイデア */
.idea-invest-complete-template-main-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding-bottom: 40px;
}

.idea-invest-complete-template-main-content-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin: 0;
  width: 95%;
}

.idea-invest-complete-template-main-content-list li {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

.idea-invest-complete-template-buttons{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}