.userpage-profile-card-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  padding-bottom: 60px;
}

.userpage-profile-card-top-container{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;
  margin-top: 20px;
}

.userpage-profile-card-user-icon{
  border: 2px solid white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
}

.userpage-profile-card-user-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}


.userpage-profile-card-bottom-container{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 50px;
  margin-top: 40px;
}

.userpage-profile-card-column-cell{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.userpage-profile-card-column-title {
  padding: 2px ;
  background-image: linear-gradient(
    to bottom,
    transparent 80%,
    rgb(171, 231, 255) 20%
  );
}