
.login-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin: 5px 20px;
  padding: 10px;
  z-index: 1002;
}

.login-popup.sp {
  padding: 5px;
}

.login-popup-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 15px;
  width: 90%;
  gap: 30px;
}

.login-popup-content.sp{
  width: 100%;
  padding: 15px 10px;
  gap: 30px;
}

.login-popup-container{
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-popup-container.sp{
  width: 100%;
}

.login-popup-form{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.login-popup-form.sp{
  width: 100%;
}

.login-popup-auth-links{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.login-popup-form-submit-button{
  width: 100%;
}

.login-popup-sns-buttons{
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.login-popup-sns-buttons.sp{
  width: 100%;
}







.success-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 10px;
  margin: 5px 20px;
  padding: 40px 20px 60px 20px;
  gap: 20px;
}

.check-container {
  background-color: #22c55e; /* green-500 */
  border-radius: 9999px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
  animation: popIn 0.5s ease-out forwards;
}

.success-popup-text {
  position: absolute;
  text-align: center;
  bottom: 15px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  animation: slideUp 0.5s ease-out;
}

/* チェックマークが弾むアニメーション */
@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  60% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* メッセージが下から滑らかに出現するアニメーション */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(2rem);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}