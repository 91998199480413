.tag-checkbox-grid-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}

.tag-checkbox-grid-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}
