
.en-title-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding-bottom: 20px;
  width: 100%;
}

.en-title-section-heading-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 10px 0px 10px 15px;
  border-left: 5px solid #383E86;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 20px;
}

.en-title-section-heading-title{  
  font-size: 16px;
  color: #6f76c3;
  font-weight: 400;
}

.en-title-section-heading-subtitle{
  font-size: 20px;
  color: #383E86;
  font-weight: 400;
}