.idea-activities-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.idea-activities-cell{
  display: flex;
  flex-direction: column;
  gap: 25px;
}