.idea-appeal-and-story-cell{
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  border-radius: 5px;
  gap: 25px;
  margin-bottom: 25px;
}

.idea-appeal-and-story-cell.sp{
  gap: 20px
}

.idea-appealpoints-cell{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.idea-story-text{
  line-height: 1.6 !important;
}