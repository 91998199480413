.card-grid-container.columns-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.card-grid-container.columns-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.card-grid-container.columns-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.card-grid-container.columns-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}