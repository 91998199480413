.notifications-template{
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: whitesmoke;
  padding-top: 50px;
  padding-bottom: 150px;
}

.notifications-template-main{
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  display: flex;
  background-color: white;
  padding: 20px;
}

.notifications-template-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: -40px;
  height: 40px;
}

.notifications-template-main-titles{
  display: flex;
  justify-content: space-between;
  width: 100%;
}