.card-grid-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.card-grid-container {
  display: grid;
  grid-gap: 12px;
  width: 100%;
}

.card-grid-item{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.card-grid-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.card-grid-header.pc{
  margin-top: 40px;
}

.card-grid-header-title{
  display: flex;
  align-items: center;  
  gap: 10px;
}


.card-grid-no-item{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 50px 0px;
}

.card-grid-no-item-text{
  font-size: 18px;
  color: #363636;
  font-weight: 400;
}
.card-grid-no-item-text.pc{
  font-size: 20px;
  color: #363636;
}

