.chat-molecules-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
}

.chat-molecules-container.left {
  flex-direction: row;
}

.chat-molecules-container.right {
  flex-direction: row-reverse;
}

.chat-messages{
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  gap: 5px;
}

.chat-messages.left{
  flex-direction: row;
}

.chat-messages.right{
  flex-direction: row-reverse;
}

.chat-messages-read-time{
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.chat-messages-read-time.left{
  align-items: flex-start;
}

.chat-messages-read-time.right{
  align-items: flex-end;
}