/* TooltipPopover.Ions.css */
.tooltip-icon {
  color: #6B7280;
  cursor: help;
  transition: color 0.2s ease;
}

.tooltip-icon:hover {
  color: #4B5563;
}

.tooltip-content {
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
              0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding: 12px 16px;
  min-width: 200px;
  max-width: 300px;
}

.tooltip-title {
  color: #111827;
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 8px 0;
  padding-bottom: 8px;
  border-bottom: 1px solid #E5E7EB;
}

.tooltip-text {
  color: #4B5563;
  font-size: 13px;
  line-height: 1.5;
  margin: 0;
  white-space: pre-wrap;
  word-break: break-word;
}