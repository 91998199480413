
.notify-button-ions {
  color: rgb(111, 111, 111);
  border-color: transparent;
  background-color: transparent;
  border-radius: 100px;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notify-button-icon-ions.extra-small {
  font-size: 18px;
}
.notify-button-icon-ions.small {
  font-size: 20px;
}
.notify-button-icon-ions.normal {
  font-size: 24px;
}
.notify-button-icon-ions.medium {
  font-size: 26px;
}
.notify-button-icon-ions.large {
  font-size: 28px;
}
.notify-button-icon-ions.extra-large {
  font-size: 36px;
}