
.img-ions-wrapper {
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idea-img-ions{
  /* aspect-ratio: 5/3; */
  aspect-ratio: 7/4;
  border-radius: 5px;
  width: 100%;
}

.user-banner-img-ions{
  aspect-ratio: 9/3;
}

.request-img-ions{
  border: 1.5px solid lightgrey;
  aspect-ratio: 7/5.5;
  border-radius: 15px;
}

.img-ions-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 24px;
  color: red;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.idea-img-ions-delete-button:hover {
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
}

.idea-img-ions-adopted-container{
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.749);
  width: 80px;
}

.idea-img-ions-adopted-img{
  width: 100%;
  height: 100%;
}