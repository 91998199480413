.comment-textfield-wrapper-atoms{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  border-radius: 10px;
}
.comment-textfield-wrapper-atoms.primary{
  background-color: #EBF0F5;
}
.comment-textfield-wrapper-atoms.secondary{
  background-color: #faf1f1;
}

.comment-textfield-text-wrapper{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 10px;
}

.comment-textfield-text-wrapper.sp{
  padding: 6px;
}

.comment-textfield-input{
  width: 100%;
  background-color: transparent;
  border: none;
}
.comment-textfield-input:focus{
  background-color: transparent;
  border: none;
  outline: none; /* デフォルトのアウトラインを削除 */
}

.comment-textfield-button-wrapper{
  display: flex;
  align-items: flex-end;
  margin-right: 6px;
  height: 100%;
}
.comment-textfield-button-wrapper.sp{
  margin-right: 3px;
}

.comment-textfield-button{
  font-weight: 500;
  background-color: transparent;
  padding: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 4px;
}
.comment-textfield-button.primary{
  color: #383E86;
}
.comment-textfield-button.secondary{
  color: #f36e70;
}


.comment-textfield-button.sp{
  margin-bottom: 4px;
}