
.auth-template-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: whitesmoke;
  height: 100vh;
  padding: 35px;
  width: 100%;
}

.auth-template-card{
  display: flex;
  justify-content: space-between;
  background-color: white;
  width: 420px;
  flex-direction: column;
  padding: 25px 30px 35px 30px;
  gap: 30px;
}

.auth-template-container.sp {
  background-color: white;
  padding: 15px;
  height: 100vh;
}

.auth-template-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 15px;
}



.auth-template-content.sp{
  background-color: whitesmoke;
  margin-top: 20px;
  width: 95%;
}

.auth-template-main{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.auth-template-form{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.auth-template-nav-links{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.auth-template-submit-button{
  width: 100%;
}

.auth-template-sns-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 100%;
}

.success-template {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  border-radius: 10px;
  margin: 5px 20px;
  padding: 40px 20px 60px 20px;
  gap: 20px;
}

.check-container {
  background-color: #22c55e; /* green-500 */
  border-radius: 9999px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0);
}

.success-template-text {
  position: absolute;
  text-align: center;
  bottom: 15px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}
