.top-comment-card-molecules-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  background-color: white;
  width: 100%;
  z-index: 2;
}

.top-comment-card-molecules-main{
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: none;
}

.top-comment-card-molecules-text{
  pointer-events: none;
}