.search-field-container {
  position: relative;
  width: 100%;
  border-radius: 5px;
  min-width: 250px;
}

.search-field-icon-wrapper {
  position: absolute;
  left: 0.75rem; 
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  display: flex;
  align-items: center;
  height: 100%;
}

.search-field-input {
  width: 100%;
  padding: 0.5rem 1rem 0.5rem 2.5rem; 
  font-size: 0.875rem;
  border: 1px solid #D1D5DB;
}

.search-field-input:focus {
  outline: none;
  border-color: #3B82F6;
  box-shadow: 0 0 0 1px #3B82F6;
}