.notify-card-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
  gap: 5px;
  border-bottom: 0.5px solid #cccccc;
  cursor: pointer;
}

.notify-card-container.read {
  background-color: rgb(234, 234, 234);
}

.notify-card-titles {
  display: flex;
  gap: 5px;
  flex-direction: column;
  pointer-events: none;
  align-items: flex-start; 
  width: 85%;
}

.notify-card-date{
  pointer-events: none;
  width: 15%;
}