
.verify-email-template{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 95vh;
  gap: 10px;
  padding: 20px;
  padding-top: 30px;
  background-color: #F6F8FA;
}


.verify-email-template-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 95vh;
  gap: 10px;
  padding-top: 30px;
  
}

.verify-email-template-main.sp{
  width: 100%;
}

.verify-email-template-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  width: 95%;
  margin: 0;
}

.verify-email-template-list li {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 15px;
}


.verify-email-template-title{
  padding: 2px ;
  background-image: linear-gradient(
    to bottom,
    transparent 80%,
    rgb(171, 231, 255) 20%
  );
}

.email-verify-complete-buttons{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}