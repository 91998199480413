/* 出資テンプレートのスタイル */
.idea-adopt-request-confirm-template{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background-color: #F6F8FA;
}

/* 出資テンプレートのタイトルヘッダー */
.idea-adopt-request-confirm-template-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}


.idea-adopt-request-confirm-template-main-heading{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 10px 0px 10px 15px;
  border-left: 5px solid #383E86;
  border-bottom: 1px solid lightgrey;
}

/* 出資テンプレートのタイトルヘッダーのタイトル */
.idea-adopt-request-confirm-template-header-title{
  font-size: 32px;
}

.idea-adopt-request-confirm-template-main-heading-title{
  font-size: 16px;
  color: #6f76c3;
  font-weight: 400;
}


/* 出資テンプレート全体のスタイル */
.idea-adopt-request-confirm-template-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
}

/* 出資テンプレートのアイデア */
.idea-adopt-request-confirm-template-main-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding-bottom: 40px;
}

.idea-adopt-request-confirm-template-main-content-texts-money{
  display: flex;
  justify-content: space-between;
  width: 90%;
  gap: 10px;
}

.idea-adopt-request-confirm-template-main-content-texts-money-title{
  display: flex;
  align-items: center;
  gap: 5px;
}

.idea-adopt-request-confirm-template-main-content-texts-money-value{
  line-height: 1.6;
}

.idea-adopt-request-confirm-template-buttons{
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}