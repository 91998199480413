.return-card-molecules-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #F6F8FA;

}

.return-card-molecules-header{
  width: 100%;
  display: flex;
  flex-direction: column;
  border-left: 10px solid #383E86;
  border-bottom: 1px solid lightgrey;
  padding: 5px 20px;
}

.return-card-molecules-main{
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  width: 100%;
}

.return-card-molecules-main-buttons{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  width: 100%;
}