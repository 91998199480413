.purchase-card-container {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
  border-bottom: 0.5px solid #cccccc;
  cursor: pointer;
}

.purchase-card-info {
  display: flex;
  gap: 5px;
  flex-direction: column;
  pointer-events: none;
}

.purchase-card-info.pc {
  gap: 10px;
}