
.how-to-use-template-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 20px;
  padding-top: 20px;
  background-color: #F6F8FA;
  padding-bottom: 100px;
}

.how-to-use-template{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  gap: 20px;
  width: 600px;
}
.how-to-use-template.sp{
  width: 100%;
}

.how-to-use-template-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  width: 100%;
}

.how-to-use-template-main-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 95%;
}

.how-to-use-template-button{
  background-color: #383e86;
  color: white;
}

.how-to-use-template-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin: 0;
}

.how-to-use-template-list li {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 15px;
}


.how-to-use-template-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.how-to-use-template-title{
  width: 100%;
  border: 1px solid #6f76c3;
  text-align: center;
  color: #6f76c3;
  padding: 10px;
}