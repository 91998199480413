.checkbox-atoms-container {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.checkbox-atoms-container.small {
  gap: 2px;
}
.checkbox-atoms-container.medium {
  gap: 5px;
}
.checkbox-atoms-container.large {
  gap: 8px;
}

.checkbox-atoms-input {
  appearance: none;
  width: 1rem;
  height: 1rem;
  background-color: white;
  border-color: #d1d5db;
  border: 1px solid grey;
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer;
}

.checkbox-atoms-input:checked {
  color: white;
  background: linear-gradient(to right, #89EAD8, #3A85DC);
  border: none;
}

.checkbox-atoms-input:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.6rem;
  height: 0.6rem;
  background-color: white;
  clip-path: polygon(15% 45%, 0% 65%, 50% 100%, 100% 0%, 85% 0%, 45% 75%);
}

.checkbox-atoms-input:disabled {
  background-color: #e5e7eb;
  border-color: #d1d5db;
}

.checkbox-atoms-input:disabled:checked {
  color: #9ca3af;
}

.checkbox-atoms-label {
  font-size: 14px;
  color: #374151;
  cursor: pointer;
}
.checkbox-atoms-label.small {
  font-size: 12px;
}
.checkbox-atoms-label.medium {
  font-size: 14px;
}
.checkbox-atoms-label.large {
  font-size: 16px;
}

.checkbox-atoms-label:disabled {
  color: #9ca3af;
}
