/* 利用規約ページ全体のスタイル */
.user-agreement-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FA;
  min-height: 100vh;
}

.user-agreement-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem;
  margin-bottom: 6rem;
  gap: 2rem;
}

.user-agreement-content.sp {
  width: 90%;
  margin-bottom: 5rem;
}

.user-agreement-title {
  color: #333;
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.02em;
}

.user-agreement-content.sp .user-agreement-title {
  font-size: 24px;
}

/* 利用規約詳細部分のスタイル */
.user-agreement-details {
  background-color: white;
  padding: 4rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.user-agreement-content.sp .user-agreement-details {
  padding: 2rem 1.5rem;
  gap: 2rem;
}

/* 章のスタイル */
.agreement-chapter {
  color: #333;
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 1.5rem 0;
  letter-spacing: 0.01em;
}

.user-agreement-content.sp .agreement-chapter {
  font-size: 20px;
  margin-bottom: 1rem;
}

/* セクションのスタイル */
.agreement-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 1rem;
}

.user-agreement-content.sp .agreement-section {
  padding-left: 0.5rem;
  gap: 1.5rem;
}

/* 条のスタイル */
.agreement-article {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* 条のタイトルのスタイル */
.article-title {
  color: #333;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.user-agreement-content.sp .article-title {
  font-size: 16px;
}

/* 条の本文のスタイル */
.article-text {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 1.8;
  letter-spacing: 0.01em;
}

.user-agreement-content.sp .article-text {
  font-size: 14px;
  line-height: 1.6;
}

/* リストのスタイル */
.article-list {
  list-style-type: decimal;
  padding-left: 2rem;
  margin: 0.5rem 0;
}

.article-list-item {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 1.6;
  padding-left: 0.5rem;
}

.user-agreement-content.sp .article-list-item {
  font-size: 14px;
  margin-bottom: 0.8rem;
}

/* 制定日のスタイル */
.establishment-date {
  color: #555;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2rem;
  text-align: right;
}

.user-agreement-content.sp .establishment-date {
  font-size: 12px;
  margin-top: 1.5rem;
}