.activity-card-molecules-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(245, 250, 250);
    gap: 10px;
    border-radius: 3px;
    padding: 10px 20px;
    padding-bottom: 15px;
}

.activity-card-molecules-header{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
}

.activity-card-molecules-header-texts{
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.activity-card-molecules-main{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 5px;
  width: 100%;
}
