

.task-card-container{
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  border-radius: 5px;
  box-shadow: 1 1 10px rgba(0, 0, 0, 0.5);
}

.task-card-container-left{
  display: flex;
  align-items: center;
  gap: 10px;
}

.task-card-confirm-button-text{
  pointer-events: none;
}