body {
  margin: 0;
  font-family:  'Hiragino Sans', 'Arial', 'sans-serif',-apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 370px;
  background-color: #F6F8FA;
  scrollbar-width: thin; /* スクロールバーの幅を調整 */
  scrollbar-color: #F6F8FA #F6F8FA; /* スクロールバーの色を設定 */
}

html {
  background-color: #F6F8FA;
}

/* スクロールバーのトラック（背景） */
::-webkit-scrollbar-track {
  background-color: #F6F8FA;
}

/* スクロールバーのハンドル（スクロールバーの移動部分） */
::-webkit-scrollbar-thumb {
  background-color: #F6F8FA;
}

/* スクロールバーのコーナー（ハンドルとトラックの接合部分） */
::-webkit-scrollbar-corner {
  background-color: #F6F8FA;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
