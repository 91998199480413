.home-template{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F8FA;
  /* background-color: transparent; */
  /* background-color: #2B1B41; */
  padding-bottom: 100px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.home-background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.15;
}

/* mainコンテナのスタイル */
.home-template-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
}

/* mainの下部のスタイル */
.home-template-main-bottom{
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.home-template-main-cell{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-bottom: 35px;
}

.home-template-main-carousels{
  display: flex;
  flex-direction: column;
  gap: 80px;
}