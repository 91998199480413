
.create-corp-template-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background-color: whitesmoke;
  padding: 35px;
  width: 100%;
}
.create-corp-template-container.sp{
  padding: 15px;
}

.create-corp-template-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  position: relative;
  z-index: 2;
}

.create-corp-template-main{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 600px;
  align-items: flex-start;
  padding: 100px 30px 100px 30px;
  background-color: white;
  position: relative;
  top: -75px;
}
.create-corp-template-main.sp{
  padding: 100px 10px 100px 10px;
  width: 100%;
}

.create-corp-template-form{
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.create-corp-template-info-container-textfields-row{
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}

.create-corp-template-form-submit-button{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  gap: 10px;
}