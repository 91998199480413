.like-card-container {
  width: 100%;
  align-items: flex-start;
  display: flex;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
}
.like-card-container.pc {
  gap: 15px;
}


.like-card-img{
  width: 30%;
  border-radius: 10px;
}

.like-card-img.pc{
  width: 40%;
}

.like-card-texts {
  width: 73%;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  gap: 5px;
}
.like-card-texts.pc {
  gap: 10px;
}

.like-card-info.pc {
  gap: 5px;
  width: 83%;
}

.idea-card-img {
  width: 27%;
  border-radius: 10px;
} 

.idea-card-img.pc {
  width: 17%;
} 