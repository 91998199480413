.request-card-container {
  position: relative;
  cursor: pointer;
  border-radius: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* スムーズな拡大アニメーション */
}

.request-card-container.hover-effect{
  margin: 10px auto;
}

.request-card-container.hover-effect:hover {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  transform: scale(1.04);
}


.request-card-img {
  width: "100%";
  aspect-ratio: 1;
}

.request-card-bottom-texts-tag{
  color: #DEDEDE;
}

.request-card-bottom-texts {
  position: absolute;
  bottom: 0;
  height: 38%;
  left:0;
  right: 0;
  background-color: rgba(0, 0, 0, 55%);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  pointer-events: none;
}
.request-card-bottom-texts.sp {
  height: 48%;
}


.request-card-bottom-texts.null {
  height: 0%;
  background-color: transparent
}

.request-card-bottom-texts-tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.request-card-rainbow-button {
  bottom: 10px;
  right: 10px;
}

.request-card-button-text{
  pointer-events: none;
  z-index: 1;
}