.about-card-molecules-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding-bottom: 30px;
}

.rotating-text-container {
  position: relative;
  width: 100%;
  height: 50px;
  max-width: 300px;
}

.about-card-molecules-number {
  font-size: 16px;
  color: #2A2A2A;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 0.5px solid #2A2A2A;
  z-index: 2;
  font-size: 16px;
  background-color: white;
}

.rotating-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.circle {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 35px;
  height: 35px;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}

.circle svg {
  width: 100%;
  height: 100%;
  overflow: visible;
  /* 表示領域を広げる */
  transform: scale(1.7);
}

.rotating-path {
  fill: none;
}

.about-card-molecules-text {
  font-size: 6px;
  font-weight: bold;
  fill: #6D6D6D;
}

.about-card-molecules-main{
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.about-card-molecules-img{
  width: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
}

.about-card-molecules-title{
  font-size: 20px;
  font-weight: 550 !important;
  color: #2B2B2B;
}

.about-card-molecules-description{
  font-size: 16px;
  font-weight: 350 !important;
  color: #2A2A2A;
}