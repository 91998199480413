
.register-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  margin: 5px 20px;
  padding: 10px;
  z-index: 1002;
}

.register-popup-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 15px;
  width: 95%;
  gap: 30px;
}

.register-popup-content.sp{
  width: 100%;
  padding: 15px 15px;
  gap: 30px;
}

.register-popup-auth-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-popup-auth-container.sp{
  width: 100%;
}

.register-popup-auth-textfields{
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.register-popup-auth-textfields.sp{
  width: 100%;
}
.register-popup-auth-links{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.register-popup-form-submit-button {
  width: 100%;
}
.register-popup-sns-buttons-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.register-popup-sns-buttons-container.sp{
  width: 100%;
}

