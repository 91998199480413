.progress-container {
  width: 100%;
}

.progress-bar {
  width: 100%;
  height: 1.5rem;
  background-color: #e5e7eb;
  border-radius: 9999px;
  overflow: hidden;
}

.progress-bar.small {
  height: 8px;
}

.progress-bar.medium {
  height: 16px;
}

.progress-bar.large {
  height: 20px;
}

.progress-fill {
  height: 100%;
  border-radius: 9999px;
  background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
  transition: width 300ms ease-out;
}