.follow-user-confirm-popup{  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  border-radius: 10px;
  gap: 30px;
  padding: 20px 5px;
  
}

.follow-user-confirm-popup-users-container{
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.follow-user-confirm-popup-user-cell{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  padding: 15px 10px;
}

.follow-user-confirm-popup-no-user{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px 10px;
  border: 1px solid #9ecae7;
  border-radius: 8px;
  background-color: #f0f8ff;
  box-shadow: 0 2px 10px rgba(52, 152, 219, 0.15);
}