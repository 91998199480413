.radio-button-grid-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;
}

.radio-button-grid-container {
  display: grid;
  gap: 7px;
}

.radio-button-grid-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}

.radio-button-grid-container.columns-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.radio-button-grid-container.columns-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.radio-button-grid-container.columns-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.radio-button-grid-container.columns-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.radio-button-grid-container.columns-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.radio-button-grid-container.columns-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.radio-button-grid-container.columns-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
