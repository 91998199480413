/* 出資テンプレートのスタイル */
.idea-invest-template{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background-color: #F6F8FA;
}

/* 出資テンプレートのタイトルヘッダー */
.idea-invest-template-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 出資テンプレートのタイトルヘッダーのタイトル */
.idea-invest-template-header-title{
  font-size: 32px;
}

/* 出資テンプレート全体のスタイル */
.idea-invest-template-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
}

/* 出資テンプレートのアイデア */
.idea-invest-template-main-content{
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  gap: 15px;
  background-color: white;
  padding-bottom: 40px;
}

.idea-invest-template-img{
  width: 40%;
  border-radius: 5px;
}
.idea-invest-template-img.sp{
  width: 60%;
}

.idea-invest-template-main-content-texts{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.idea-invest-template-main-content-texts.sp{
  gap: 15px;
}

.idea-invest-template-main-content-texts-money{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idea-invest-template-main-content-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin: 0;
}

.idea-invest-template-main-content-list li {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 15px;
}

.idea-invest-template-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}