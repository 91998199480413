.sns-round-button-atoms.small {
  height: 35px !important;
  width: 220px !important;
  font-size: 14px !important;
}

.sns-round-button-atoms.medium {
  height: 40px !important;
  width: 240px !important;
  font-size: 16px !important;
}

.sns-round-button-atoms.large {
  height: 40px !important;
  width: 100% !important;
  font-size: 16px !important;
}

.line-button {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #06C755;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  justify-content: flex-start;
  width: 240px;
  height: 40px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 2px;
}

/* ホバーオーバーレイ */
.line-button:hover .hover-overlay {
  opacity: 0.1; /* マウスオーバー時の不透明度 */
}

.line-button:active .hover-overlay {
  opacity: 0.3; /* マウスクリック時の不透明度 */
}
.hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000; /* 黒 */
  border-radius: 6px;
  opacity: 0; /* 初期状態は透明 */
  transition: opacity 0.3s ease; /* スムーズなホバーアニメーション */
  pointer-events: none; /* 他の要素の操作を邪魔しない */
}


.line-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(229, 229, 229, 0.6);
  height: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.line-icon img {
  width: 34px;
  height: 34px;
}

.login-text {
  font-weight: 500;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
