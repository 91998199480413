.user-popover-wrapper{
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.user-popover-wrapper-nav-texts{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
