.checkbox-grid-container-wrapper {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: 100%;
}

.checkbox-grid-container {
  display: grid;
  gap: 7px;
}

.checkbox-grid-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}

.checkbox-grid-container.columns-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.checkbox-grid-container.columns-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.checkbox-grid-container.columns-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.checkbox-grid-container.columns-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.checkbox-grid-container.columns-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.checkbox-grid-container.columns-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.checkbox-grid-container.columns-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.checkbox-error-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.checkbox-grid-error-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%;
}

.checkbox-grid-error {
  font-size: 12px;
  color: #e74c3c;
  margin-top: 5px;
  flex: 1;
  text-align: left; 
}

.checkbox-grid-counter {
  font-size: 12px;
  color: #333;
  margin-top: 5px;
  flex: 1;
  text-align: right; 
}

.checkbox-grid-counter.max {
  color: #e74c3c;
}


