.request-result-no-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 20px;
}
.request-result{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 5px;
  gap: 20px;
}

.request-result-idea{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 70%;
  padding: 20px;
}
.request-result-idea.sp{
  width: 90%;
  padding: 10px;
}