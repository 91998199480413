.button-atoms {
  background-color: white;
  color: grey;
  border: 1px solid lightgrey;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease;
}

.button-atoms:disabled {
  background-color: lightgrey;
  color: grey;
  border-color: lightgrey;
  font-weight: normal;
  opacity: 0.9;
}

.button-atoms.extra-small {
  padding: 6px 10px;
  font-size: 12px;
}

.button-atoms.small {
  padding: 6px 10px;
  font-size: 14px;
}

.button-atoms.normal {
  padding: 7px 16px;
  font-size: 16px;
}

.button-atoms.medium {
  padding: 8px 20px;
  font-size: 18px;
}

.button-atoms.large {
  padding: 12px 30px;
  font-size: 24px;
}

.button-atoms.extra-large {
  padding: 15px 40px;
  font-size: 30px;
}

.button-atoms.purple:not(:disabled) {
  background-color: #383E86;
  color: white;
}
.button-atoms.purple:hover:not(:disabled) {
  background-color: #424a9c;
}

.button-atoms.pink:not(:disabled) {
  background-color: rgb(254, 89, 91);
  color: white;
}
.button-atoms.pink:hover:not(:disabled) {
  background-color: rgb(217, 79, 81);
}

.button-atoms.red:not(:disabled) {
  background-color: rgb(229, 39, 42);
  color: white;
}
.button-atoms.red:hover:not(:disabled) {
  background-color: rgb(220, 57, 59);
}

.button-atoms.white:not(:disabled) {
  background-color: white;
  border: 0.5px solid lightgrey;
  color: grey;
}
