/* 特定商法取引ページ全体のスタイル */

table th, table td {
  white-space: undefined;
}

.commercial-transaction-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FA;
  min-height: 100vh;
}

.commercial-transaction-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem;
  margin-bottom: 6rem;
  gap: 2rem;
}

.commercial-transaction-content.sp {
  width: 90%;
  margin-bottom: 5rem;
}

.commercial-transaction-title {
  color: #333;
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.02em;
}

.commercial-transaction-content.sp .commercial-transaction-title {
  font-size: 24px;
}

/* テーブルのスタイル */
.commercial-transaction-table {
  width: 100%;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: scroll;
}

.commercial-transaction-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.commercial-transaction-table th,
.commercial-transaction-table td {
  padding: 1.5rem;
  text-align: left;
  vertical-align: top;
  line-height: 1.8;
  font-weight: 400;
}

.commercial-transaction-content.sp .commercial-transaction-table th,
.commercial-transaction-content.sp .commercial-transaction-table td {
  padding: 1rem;
  line-height: 1.6;
}

.commercial-transaction-table th {
  width: 20%;
  font-size: 16px;
  color: #444;
  border-right: 1px solid #ededed;
  background-color: #fafafa;
}

.commercial-transaction-content.sp .commercial-transaction-table th {
  width: 30%;
  font-size: 14px;
}

.commercial-transaction-table td {
  font-size: 16px;
  color: #444;
}

.commercial-transaction-content.sp .commercial-transaction-table td {
  font-size: 14px;
}

.commercial-transaction-table tr {
  border-bottom: 1px solid #ededed;
}

.commercial-transaction-table tr:last-child {
  border-bottom: none;
}