.header-title-sakairi-molecules{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1;
}

.header-title-sakairi-molecules.center{
  align-items:center;
}

.header-title-sakairi-molecules-title{
  font-size: 58px;
  font-weight: bold;
  color: #40B2D3;
  font-family: Impact, Arial Black, sans-serif;
  letter-spacing: 3px;
}
.header-title-sakairi-molecules-title.extra-large{
  font-size: 78px;
}
.header-title-sakairi-molecules-title.large{
  font-size: 58px;
}
.header-title-sakairi-molecules-title.medium{
  font-size: 40px;
}
.header-title-sakairi-molecules-title.small{
  font-size: 32px;
}


.header-title-sakairi-molecules-subtitle{
  font-size: 20px;
  font-weight: bold;
  color: #2A2A2A;
  white-space: pre-wrap;
}
/* スマホサイズの画面では16pxに変更 */
@media screen and (max-width: 767px) {
  .header-title-sakairi-molecules-subtitle{
    font-size: 16px;
  }
}