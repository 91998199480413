.step-navigation {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
}

.step-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 115px;
}

.step-container.first-step {
  align-items: flex-start;
}

.step-container.last-step {
  align-items: flex-end;
}

.step-container.middle-step {
  width: 150px;
  align-items: center;
  text-align: center;
}

.step-circle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  padding: 10px;
  border: 1px solid;
  border-radius: 50%;
}

.step-circle.with-after::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  height: 2px;
  width: 36px;
  background-color: #383E86;
}

.step-circle.with-before::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  height: 2px;
  width: 36px;
  background-color: #383E86;
}

.step-circle.disabled.with-after::after,
.step-circle.disabled.with-before::before {
  height: 1px;
  background-color: #D1D5DB;
}

.step-label {
  font-size: 14px;
}

.step-number {
  font-size: 24px;
  font-weight: 500;
  line-height: 0.98;
  letter-spacing: -1px;
}

.step-title {
  font-size: 14px; /* 14px */
  white-space: pre-line;
  font-weight: 600;
}