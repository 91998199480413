.not-found-template {
  min-height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.not-found-template-container {
  width: 100%;
  text-align: center;
}

.not-found-template-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-found-template-title {
  color: #383E86;
  margin-bottom: 16px;
}

.not-found-template-subtitle {
  margin-bottom: 16px;
}

.not-found-template-description {
  color: #6B7280;
  margin-bottom: 32px;
  line-height: 1.6;
}

.not-found-template-button {
  background-color: #383E86;
  color: white;
  padding: 12px 24px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.2s;
}

.not-found-template-button:hover {
  background-color: #2D3282;
}

.not-found-template-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(56, 62, 134, 0.3);
}