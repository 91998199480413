.task-card-grid-container {
  display: grid;
  width: 500px;
  margin-top: 30px;
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 101;
  gap: 5px;
}

.task-card-grid-container.sp {
  width: 95%;
  margin-top: 30px;
  bottom: 10px;
}

.task-card-container-left-texts{
  display: flex;
  flex-direction: column;
  gap: 5px;
}