
.setting-button-ions {
  color: grey;
  border-color: transparent;
  background-color: transparent;
  border-radius: 100px;
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setting-button-icon-ions.extra-small {
  font-size: 18px;
}
.setting-button-icon-ions.small {
  font-size: 20px;
}
.setting-button-icon-ions.normal {
  font-size: 24px;
}
.setting-button-icon-ions.medium {
  font-size: 26px;
}
.setting-button-icon-ions.large {
  font-size: 28px;
}
.setting-button-icon-ions.extra-large {
  font-size: 30px;
}