.reward-confirm-popup{  
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 10px;
  margin: 5px 10px;
  padding: 15px;
  gap: 30px;
  padding-bottom: 20px;
}

.reward-confirm-popup-rewards{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}

.reward-confirm-popup-rewards-cells{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.reward-confirm-popup-attention{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.reward-confirm-popup-attention-cells{
  display: flex;
  flex-direction: column;
  gap: 5px;
}