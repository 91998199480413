.notify-card-grid-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.notify-card-grid-container-header{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  padding: 10px 0px;
}

.notify-card-grid-container-header-button-text{
  pointer-events: none;
}

.notify-card-grid-not-exists{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  color: rgb(96, 96, 96);
  background-color: rgb(237, 246, 251);
  border-radius: 8px;
}