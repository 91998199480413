.header-title-molecules{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title-molecules-title{
  font-size: 36px;
  color: #383E86;
  font-weight: 600;
  text-align: center;
}

.header-title-molecules-subtitle{
  font-size: 20px;
  color: #444444;
  text-align: center;
}