.request-null-card{
  aspect-ratio: 7/2 !important;
  width: 100%;
}

.request-card-null-wrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  border: 1px solid lightgrey;
  padding: 24px 5px 20px 5px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.request-card-null-text{
  font-size: 18px;
  font-weight: 500;
  color: #8b8b8b !important;
  text-align: center;
}

.request-card-null-description{
  font-size: 14px;
  font-weight: 500;
  color: #8b8b8b !important;
  text-align: center;
}