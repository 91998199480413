
.userpage-mode-tab-buttons{
  margin-top: 40px;
  width: 100%;
  padding-left: 10px;
  display: flex;  /* 必要 */
  gap: 5px;
  flex: 1;
  overflow-x: scroll;
  scroll-behavior: smooth;
  /* スクロールバーを非表示にする */
  -ms-overflow-style: none;    /* IE, Edge */
  scrollbar-width: none;       /* Firefox */
  &::-webkit-scrollbar {
    display: none;             /* Chrome, Safari */
  }
}

.userpage-mode-tab-button{
  flex-wrap: nowrap;
  flex-shrink: 0;
  white-space: nowrap;
}