.app-footer-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  padding: 50px 0;
  background-color: #383E86;
  position: relative;
  z-index: 1;
  padding-bottom: 150px;
}

.app-footer-sp-main{
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
}

.app-footer-pc-main{
  width: 90%;
  display: flex;
  flex-direction: row;
  gap: 25px;
  justify-content: space-between;
}

.app-footer-pc-right{
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.app-footer-logo-text{
  color: white;
  font-size: 30px;
  font-weight: 800 !important;
  font-family: "Hiragino Sans", Arial, sans-serif;
}
.app-footer-categories-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.app-footer-categories-wrapper.pc {
  width: 280px;
}


.app-footer-categories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  width: 100%;
}

.app-footer-category-text{
  color: white;
  font-size: 16px;
  font-weight: 500;
}

.app-footer-links{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.app-footer-link-text{
  color: white;
  font-size: 16px;
  font-weight: 500;
}