.error-card-container{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  background-color: #f3cdcd;
  border: 1.5px solid #e64040;
  border-radius: 3px;
  gap: 10px;
}

.error-card-icon{
  font-size: 20px;
  color: #e83f3f;
}