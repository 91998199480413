.app-header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  /* background-color: #0D1226; */
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  z-index: 100;
  width: 100%;
}

.app-header.sp {
  padding: 10px 12px;
  min-width: unset;
}

.app-header-logo-search-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.auth-buttons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.app-header-icons {
  display: flex;
  align-items: center;
  gap: 20px;
}
.app-header-icons.sp {
  gap: 15px;
}

.app-header-menu {
  display: flex;
  align-items: center;
  gap: 22px;
  transition: gap 0.1s ease;
  margin-right: 12px;
}
.app-header-menu.small {
  gap: 15px;
  margin-right: 12px;
  z-index: 1;
}
.app-header-auth-button{
  padding: 6px 16px;
  font-size: 15px;
  transition: padding 0.1s ease, font-size 0.1s ease, ; /* スムーズなアニメーションのため */
}

.app-header-auth-button.sm{
  padding: 6px 12px;
  font-size: 12px;
}

.app-header-menu-label{
  font-family: "Zen Maru Gothic";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.7px;
  color: #2A2A2A;
  cursor: pointer;
  transition: transform 0.2s ease, font-size 0.1s ease; /* スムーズなアニメーションのため */
}
.app-header-menu-label.small{
  letter-spacing: 1.5px;
  font-size: 12px;
}
.app-header-menu-label.small:hover{
  transform: scale(1.1); /* hover時に1.1倍に拡大 */
}
.app-header-menu-label:hover {
  transform: scale(1.1); /* hover時に1.1倍に拡大 */
}

.app-header-menu-icon{
  width: 40px !important;
  height: 40px !important;
  padding: 5px;
  font-size: 20px;
  z-index: 1;
}

.app-header-menu-icon-wrapper {
  position: relative;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 虹色の背景を持つ疑似要素 */
.app-header-menu-icon-wrapper::before {
  content: '';
  position: absolute;
  inset: 0; /* top: 0, right: 0, bottom: 0, left: 0 の省略形 */
  border-radius: 50%;
  background: conic-gradient(
    from 0deg,
    #ff6b6b 0deg,
    #ffd93d 90deg,
    #6bcb77 180deg,
    #4d96ff 270deg,
    #ff6b6b 360deg
  );
  padding: 1.5px; /* これが境界線の太さになります */
}

/* 白い背景を持つ内側の要素 */
.app-header-menu-icon-wrapper::after {
  content: '';
  position: absolute;
  inset: 1.5px; /* border の太さ分だけ内側に */
  background: white;
  border-radius: 50%;
  z-index: 0;
}

.app-header-menu-icon {
  width: 40px !important;
  height: 40px !important;
  position: relative;
  color: #7D7D7D;
  z-index: 1;
}