.round-like-button-ions-wrapper{
  display: flex;
  align-items: center;
  gap: 15px;
}
.round-like-button-ions:not(:disabled) {
  color: rgb(254, 89, 91);
  border-color: rgb(254, 89, 91);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
} 

/* disabled時のスタイル */
.round-like-button-ions:disabled {
  padding: 10px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

/* 選択時のスタイル */
.round-like-button-ions.selected:not(:disabled) {
  background-color: rgb(254, 89, 91);
  color: white;
}

.round-like-button-ions-text{
  font-size: 18px;
}

.like-button-ions-num-of-likes {
  background-color: rgb(254, 89, 91);
  padding: 2px 6px;
  color: white;
  display: flex;
  justify-content: center; /* 水平方向の中央揃え */
  align-items: center;     /* 垂直方向の中央揃え */
  border-radius: 5px;     
  font-size: 22px;
}

/* 選択時のいいね数カウンターのスタイル */
.round-like-button-ions.selected:not(:disabled) {
  background-color: rgb(254, 89, 91);
  color: white;
}

.like-button-ions-num-of-likes.selected {
  background-color: white;
  color: rgb(254, 89, 91);
}

.like-button-ions-num-of-likes.disabled {
  background-color: grey;
  color: white;
}

/* 選択時のいいね数カウンターのスタイル */
.round-like-button-ions.selected:disabled .like-button-ions-num-of-likes.disabled {
  background-color: grey;
  color: white;
}