.search-template{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F8FA;
    padding-bottom: 100px;
    width: 100%;
}

.search-template-main{
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding-top: 70px;
}

.search-template-main.sp{
    flex-direction: column;
    width: 95%;
    gap: 30px;
    padding-top: 30px;
}

.search-template-main-nav-texts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 35px;
    width: 18%;
}

.search-template-main-nav-texts.sp{
    width: 100%;
}

.search-template-main-ideas{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    width: 79%;
}

.search-template-main-ideas.sp{
    width: 100%;
}

.search-template-main-ideas-grid{
    width: 100%;
}

.search-template-main-ideas-null{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 120px;
    padding-bottom: 120px;
    color: #A0A0A0;
}

.search-template-main-ideas-null.sp{
    padding-top: 120px;
    padding-bottom: 120px;
}
