.drawer-atoms {
  position: fixed;
  background-color: white;
  transition: transform 0.25s ease-in-out;
  z-index: 1000;
}

.drawer-atoms.left {
  top: 0;
  left: 0;
  height: 100%;
  transform: translateX(-100%);
}

.drawer-atoms.right {
  top: 0;
  right: 0;
  height: 100%;
  transform: translateX(100%);
}

.drawer-atoms.top {
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
}

.drawer-atoms.bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
}

.drawer-atoms.open {
  transform: translate(0);
}

.drawer-overlay-atoms {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  z-index: 999;
}

.drawer-overlay-atoms.visible {
  opacity: 1;
  visibility: visible;
}

.drawer-content-atoms {
  height: 100%;
  overflow-y: auto;
  z-index: 1001;
}
