.relative {
  position: relative;
  min-width: 200px;
}

.selector-label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333;
}

.select-wrapper {
  padding: 8px 10px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}

.select-wrapper:hover {
  border-color: #007bff;
}

.select-wrapper.isOpen {
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
  opacity: 1;
  animation: opacity 0.2s;  
}

.selectWrapper.disabled {
  background-color: #f9fafb;
  cursor: not-allowed;
  opacity: 0.75;
}

.select-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
  cursor: pointer;
  font-size: 14px;
}

.select-text.selected {
  color: #374151;
  width: 100%;
}

.dropdown {
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 4px;
  background-color: white;
  border: 1px solid rgb(203, 203, 203);
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.optionList {
  max-height: 240px;
  overflow-y: auto;
  padding: 4px 0;
  border-color: grey;
}

.option {
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.15s;
}

.option:hover {
  background-color: #ebf8ff;
}

.option.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.option.selected {
  background-color: #ebf8ff;
}

.option.highlighted {
  background-color: #f3f4f6;
}

.hiddenInput {
  display: none;
}

.option-text {
  font-size: 14px;
  color: #333;
}
