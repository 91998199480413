.chat-grid-container {
  display: grid;
  gap: 7px;
  width: 100%;
  padding: 5px 10px;
}

.chat-grid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.chat-grid-header-back-and-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chat-grid-container.only {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
  padding: 0px;
  background-color: white;
}

.chat-grid-and-input{
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  max-height: 520px;
  min-height: 520px;
}

.chat-grid-and-input.only{
  max-height: 100vh; /* フォールバック */
  max-height: 100dvh; /* モダンブラウザ用 */
  padding: 10px;
}

.chat-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 20px 0px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.chat-grid-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 100%;
  border-top: 1px solid #e0e0e0;
}

.chat-grid-input-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.chat-grid-image-icon{
  color: rgb(141, 141, 141);
  cursor: pointer;
}