/* アイデアテンプレートのスタイル */
.idea-edit-template{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background-color: #F6F8FA;
}

.idea-edit-template-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.idea-edit-template-header-title{
  font-size: 38px;
}

/* アイデアテンプレート全体のスタイル */
.idea-edit-template-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 20px 0px;
}

/* アイデアテンプレートのメインのヘッダー */
.idea-edit-template-main-header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idea-edit-template-main-header.sp{
  flex-direction: column;
  gap: 10px;
}

/* アイデアテンプレートのメインのヘッダーのボタン */
.idea-edit-template-main-header-buttons{
  display: flex;
  align-items: center;
  gap: 5px;
}

/* 公開設定 */
.idea-edit-template-main-display{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* アイデアテンプレートのメインのカラムデザイン */
.idea-edit-template-main-columns{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

/* アイデアテンプレートのメインのカラム(テキスト)デザイン */
.idea-edit-template-main-columns-text{
  flex: 1; /* 余った幅をすべて使用 */
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

/* アイデアテンプレートのメインのコンテントデザイン */
.idea-edit-template-main-contents{
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
}

/* アイデアテンプレートのメインのアイデア詳細のデザイン */
.idea-edit-template-main-details{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.idea-edit-template-main-contents-appealpoints{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}


.idea-edit-template-main-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
  padding: 20px 0px;
}
