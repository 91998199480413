.request-template-null{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 300px;
  background-color: #F6F8FA;
  min-height: 100vh;
}


.request-template{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F6F8FA;
    width: 100%;
}

.request-template-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  background-color: #F6F8FA;
  padding-bottom: 100px;
  padding-top: 20px;
}
.request-template-main.pc{
  width: 100%;
}
.request-categories-text{
  color: #40B2D3;
  font-family: Noto Sans JP;
}

.request-template-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.request-template-main-about{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.request-template-main-about.pc{
  gap: 20px;
}

.request-template-main-bottom{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: white;
}

.request-template-main-nav{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background-color: #CCCFE0;
  padding: 10px 15px;
}

.request-template-main-nav-button{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 7px 15px;
  background-color: white;
  border: none;
  color: #A6A6A6;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.request-template-main-nav-button.pc{
  gap: 14px;
  padding: 10px 25px;
  font-size: 18px;
}

.request-template-main-nav-button.selected{
  background-color: #383E86;
}

.request-template-main-nav-button-text{
  pointer-events: none;
}

.request-template-main-nav-button-text.selected{
  color:white;
}


.request-template-other-themes{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px 0;
  margin-bottom: 50px;
}