.textfield-atoms{
  field-sizing: content;
  min-width: 150px;
  min-height: min-content;
  overflow-wrap: break-word; /* 単語が長すぎる場合、適切に折り返す */
  word-break: break-word; /* 古いブラウザ向け */
}

.textfield-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.textfield-label {
  font-size: 14px;
  margin-bottom: 2px;
  color: #333;;
}
.textfield-label.small {
  font-size: 12px;
  margin-bottom: 5px;
}
.textfield-label.medium {
  font-size: 14px;
  margin-bottom: 8px;
}
.textfield-label.large {
  padding: 16px;
  font-size: 10px;
}

.textfield-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px !important;
  font-size: var(--font-size);
  line-height: var(--line-height);
  outline: none;
  transition: border-color 0.3s ease;
}

.textfield-input.small {
  padding: 5px;
  --font-size: 14px;
  --line-height: 1.3;
}
.textfield-input.medium {
  padding: 7px;
  --font-size: 14px;
  --line-height: 1.3;
}
.textfield-input.large {
  padding: 12px;
  --font-size: 15px;
  --line-height: 1.4;
}

.textfield-input:focus {
  border-color: #007bff;
}

/* 下線のスタイルを適用したテキストフィールド */
.textfield-input-underline {
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  padding: 4px 0;
  outline: none;
  width: 100%;
  background: transparent;
  transition: border-color 0.3s;
}

/* フォーカス時のスタイル */
.textfield-input-underline:focus {
  border-bottom: 1px solid #007BFF; /* フォーカス時の色 */
}

.textfield-input-underline::placeholder {
  font-size: 14px; /* フォントサイズを変更 */
  color: #aaa; /* プレースホルダーの色もカスタマイズ可能 */
  opacity: 1; /* プレースホルダーの透明度を調整 */
}

.textfield-error-max-container {
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.textfield-input-error {
  border-color: #e74c3c;
}

.textfield-input-error:focus {
  border-color: #e74c3c;
}

.textfield-error {
  font-size: 12px;
  color: #e74c3c;
  margin-top: 5px;
  flex: 1;
  text-align: left; 
}

.textfield-counter {
  font-size: 12px;
  color: #333;
  margin-top: 5px;
  flex: 1;
  text-align: right; 
}

.textfield-counter.max {
  color: #e74c3c;
}



