.comment-card-kubo-molecules-full{
  width: 100%;
  display: flex;
  flex-direction: column;
}

.comment-card-kubo-molecules-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #faf1f1;
  gap: 5px;
  border-radius: 15px;
  padding: 35px 20px 25px 20px;
  width: 100%;
  border-bottom: 4px solid #fedfe0;
}
.comment-card-kubo-molecules-container.reply{
  padding: 35px 20px 25px 20px;
}
.comment-card-kubo-molecules-container.owner{
  /* background-color: #d6f7ea;
  border-bottom: 4px solid #b1e0ce; */
}

.comment-card-kubo-molecules-header{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  top: -23px;
}

.comment-card-kubo-molecules-user-info{
  align-items: center;
  display: flex;
  gap: 15px;
}

.comment-card-kubo-molecules-username{
  background-color: #f2b5b6;
  font-size: 14px;
  border-radius: 50px;
  padding: 5px 10px;
  color: white;
}
.comment-card-kubo-molecules-username.owner{
  background-color: #f36e70;
}

.comment-card-kubo-molecules-role{
  background-color: #f9cbcb;
  font-size: 14px;
  border-radius: 50px;
  padding: 5px 10px;
  color: white;
}
.comment-card-kubo-molecules-role.owner{
  /* background-color: #64e5b6; */
}

.comment-card-kubo-molecules-like-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.comment-card-kubo-molecules-like-icon {
  color: #A6A6A6;
  transition: transform 0.2s ease, color 0.2s ease;
}

.comment-card-kubo-molecules-like-icon.liked {
  color: #f36e70;
}

/* アニメーション終了後、元のサイズに戻る */
@keyframes bounce {
  0% { transform: scale(1); }
  50% { transform: scale(1.3); }
  100% { transform: scale(1); }
}

.comment-card-kubo-molecules-like-icon.animate-bounce {
  animation: bounce 0.4s ease;
}

.comment-card-kubo-molecules-like-text{
  font-size: 20px;
  font-weight: 500;
  color: #A6A6A6;
}
.comment-card-kubo-molecules-like-text.liked{
  color: #f36e70;
}

.comment-card-kubo-molecules-text{
  font-size: 16px;
  font-weight: 500;
  color: #f36e70;
  text-align: left;
}
.comment-card-kubo-molecules-text.owner{
  /* color: #1ab179; */
}

.comment-card-kubo-molecules-time-wrapeer{
  position: absolute;
  bottom: 5px;
  right: 15px;
  gap: 10px;
  color: #f36e70;
}

.comment-card-kubo-molecules-time-text{
  color: #ef7e80;
  font-weight: 300;
}
.comment-card-kubo-molecules-time-text.owner{
  /* color: #1ab179; */
}

.comment-card-kubo-molecules-buttons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.comment-card-kubo-molecules-button-text{
  font-size: 16px;
  font-weight: 500;
  color: #f36e70;
  cursor: pointer;
}
.comment-card-kubo-molecules-button-text.owner{
  /* color: #1ab179; */
}

.comment-card-kubo-molecules-reply-textfield{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 40px;
  gap: 10px;
}

.comment-card-kubo-molecules-textfield-input-text{
  color: #f36e70;
}
.comment-card-kubo-molecules-textfield-input-text.owner{
  color: #1ab179;
}