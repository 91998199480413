.popup-atoms-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(40, 40, 40, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease;
  animation: fadeInOverlay 0.2s ease 0.1s forwards;
}
@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popup-atoms-overlay.hidden {
  opacity: 1;
  animation: fadeOutOverlay 0.2s ease 0.1s forwards;
}

@keyframes fadeOutOverlay {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.popup-atoms {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90%;
  background: white;
  padding: 20px 10px;
  border-radius: 8px;
  width: 300px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transform: scale(0.8);
  animation: fadeInPopup 0.3s ease 0.1s forwards;
  pointer-events: all;
}

.popup-atoms.extra-small {
  width: 300px;
}

.popup-atoms.small {
  width: 350px;
}

.popup-atoms.normal {
  width: 450px;
}

.popup-atoms.medium {
  width: 500px;
}

.popup-atoms.large {
  width: 650px;
}

.popup-atoms.extra-large {
  width: "95%";
}

@keyframes fadeInPopup {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.popup-atoms.closing {
  transform: scale(1);
  opacity: 1;
  animation: fadeOutPopup 0.3s ease 0.1s forwards;
}

@keyframes fadeOutPopup {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.8);
    opacity: 0;
  }
}

.popup-atoms-content {
  font-size: 16px;
}

.popup-atoms-close {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 36px;
  height: 36px;
  background: none;
  border: none;
  font-size: 44px;
  cursor: pointer;
  color: grey;
  text-align: center;
  line-height: 36px;
  z-index: 2;
}

.popup-atoms-close:hover {
  background-color: snow;
  border-radius: 100px;
}