.radio-button-form-button-wrapper{
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}
.radio-button-form-button-cell{
  display: flex;
  flex-direction: row;
  gap: 10px;
  opacity: 0.3;
}
.radio-button-form-button-cell.selected{
  opacity: 1;
}
.radio-button-form-button-text{
  pointer-events: none;
}
.radio-button-form-button-cell.error{
  border: 1px solid red;
}

.image-gene-form{ 
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}