.user-info-grid{
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-info-grid-cell{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.user-info-grid-text{
  pointer-events: none;
}