
.idea-popup-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
}

.idea-popup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.idea-popup {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  margin: 5px 5px;
  padding-top: 20px;
  gap: 15px;
  max-height: 520px;
  max-width: 100%;
  overflow-y: auto;
  padding-bottom: 30px;
}

.idea-popup-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.idea-popup-main-container{
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
}

.idea-popup-main-container-request-carousel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 100%;
}


.form-submit-button{
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}
