.button-circle-ions {
  border-radius: 50% !important;
  display: flex;
  font-weight: bold;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  border-color: transparent;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.button-circle-ions.extra-small {
  font-size: 16px;
  width: 35px;
  height: 35px;
}

.button-circle-ions.small {
  width: 65px;
  height: 65px;
}

.button-circle-ions.normal {
  width: 65px;
  height: 65px;
}

.button-circle-ions.medium {
  width: 80px;
  height: 80px;
}

.button-circle-ions.large {
  width: 100px;
  height: 100px;
}

.button-circle-ions.extra-large {
  width: 100px;
  height: 100px;
}