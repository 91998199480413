.header-drawer-molecules-container{
  width: 100%;
  height: 100%;
  background-color: #383E86;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.header-drawer-molecules-close-icon{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px;
}

.header-drawer-molecules-search {
  width: 100%;
  padding: 10px;
  padding-bottom: 20px;
}

.header-drawer-molecules-main-cell {
  padding: 8px 10px;
  border-top: 1px solid white;
  width: 100%;
  padding-left: 30px;
  cursor: pointer;
}

.header-drawer-molecules-main-cell.last {
  border-bottom: 1px solid white;
}

.header-drawer-molecules-main-text{
  pointer-events: none;
}

.header-drawer-molecules-main-auth-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  padding-top: 20px;
}