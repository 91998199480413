.loading-overlay-spinner{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);  /* 半透明の黒色オーバーレイ */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1004;  /* 他の要素の上に表示 */
}