
.setting-template-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: whitesmoke;
  width: 100%;
  height: 95vh;
  padding-top: 30px;
}

.setting-template-now{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.setting-template-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 2;
}

.setting-template-success{
  width: 100%;
  border: 1px solid #6f76c3;
  padding: 10px;
  font-size: 14px;
  color: #6f76c3;
  font-weight: 300;
  white-space: pre-wrap;
}

.setting-template-form{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}

.setting-template-main{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 600px;
  align-items: center;
  padding: 80px 30px 100px 30px;
  background-color: white;
  position: relative;
  top: -55px;
}
.setting-template-main.sp{
  padding: 80px 10px 100px 10px;
  width: 95%;
}

.setting-template-submit-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  margin-top: 20px;
}