/* プライバシーポリシーページ全体のスタイル */
.privacy-policy-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F6F8FA;
  min-height: 100vh;
}

.privacy-policy-content {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3rem;
  margin-bottom: 6rem;
  gap: 2rem;
}

.privacy-policy-content.sp {
  width: 90%;
  margin-bottom: 5rem;
}

.privacy-policy-title {
  color: #333;
  font-size: 28px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.02em;
}

.privacy-policy-content.sp .privacy-policy-title {
  font-size: 24px;
}

.privacy-policy-intro {
  color: #444;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  line-height: 1.6;
}

.privacy-policy-content.sp .privacy-policy-intro {
  font-size: 14px;
}

/* 詳細説明部分のスタイル */
.privacy-policy-details {
  background-color: white;
  padding: 4rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

.privacy-policy-content.sp .privacy-policy-details {
  padding: 2rem 1.5rem;
  gap: 2rem;
}

.policy-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.policy-section-title {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.01em;
}

.privacy-policy-content.sp .policy-section-title {
  font-size: 18px;
}

.policy-section-text {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  line-height: 1.8;
  letter-spacing: 0.01em;
}

.privacy-policy-content.sp .policy-section-text {
  font-size: 14px;
  line-height: 1.6;
}

.policy-list {
  list-style-type: none;
  padding-left: 1.5rem;
  margin: 0.5rem 0 0 0;
}

.policy-list-item {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.5rem;
  line-height: 1.6;
}

.privacy-policy-content.sp .policy-list-item {
  font-size: 14px;
  margin-bottom: 0.8rem;
}

.policy-list-item::before {
  content: '・';
  position: absolute;
  left: 0;
  color: #666;
}

.contact-info {
  margin-top: 1rem;
  padding: 1rem 0;
}

.company-name, .email-info {
  color: #444;
  font-size: 16px;
  font-weight: 400;
  margin: 0.5rem 0;
  line-height: 1.6;
}

.privacy-policy-content.sp .company-name,
.privacy-policy-content.sp .email-info {
  font-size: 14px;
}