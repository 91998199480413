.img-atoms-wrapper{
  position: relative;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img-atoms{
  object-fit: cover;         
  height: auto;              
  display: block;            
  max-width: 100%;           
  box-sizing: border-box;    
}

.img-atoms-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 24px;
  color: red;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}