/* 出資テンプレートのスタイル */
.idea-adopt-request-template{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  background-color: #F6F8FA;
}

/* 出資テンプレートのタイトルヘッダー */
.idea-adopt-request-template-header{
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 出資テンプレートのタイトルヘッダーのタイトル */
.idea-adopt-request-template-header-title{
  font-size: 32px;
}

/* 出資テンプレート全体のスタイル */
.idea-adopt-request-template-main{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px 0px;
}

/* 出資テンプレートのメインのヘッダー */
.idea-adopt-request-template-main-heading{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding: 10px 0px 10px 15px;
  border-left: 5px solid #383E86;
  border-bottom: 1px solid lightgrey;
}

.idea-adopt-request-template-main-heading-title{
  font-size: 16px;
  color: #6f76c3;
  font-weight: 400;
}

/* 出資テンプレートのアイデア */
.idea-adopt-request-template-main-content{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 20px;
  background-color: white;
  padding-bottom: 40px;
}

.idea-adopt-request-template-img{
  width: 40%;
  border-radius: 5px;
}
.idea-adopt-request-template-img.sp{
  width: 60%;
}

.idea-adopt-request-template-main-content-texts{
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.idea-adopt-request-template-main-content-texts.sp{
  width: 90%;
  gap: 15px;
}

.idea-adopt-request-template-main-content-texts-money{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.idea-adopt-request-template-main-content-list {
  list-style-type: disc;
  padding-left: 1.25rem;
  margin: 0;
}

.idea-adopt-request-template-main-content-list li {
  color: #4a5568;
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 15px;
}