.upload-container {
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
}

.upload-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.drop-zone {
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  width: 100%;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  gap: 10px;
}

.drop-zone.error {
  border: 1.5px solid rgb(255, 43, 43);
}

.drop-text {
  pointer-events: none;
}

.drop-zone.dragging {
  border-color: #3b82f6;
  background-color: #eff6ff;
}

.file-input-label {
  cursor: pointer;
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;
}

.file-input-label:hover {
  background-color: #2563eb;
}

.file-input {
  display: none;
}

.preview-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

@media (min-width: 768px) {
  .preview-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.preview-item {
  position: relative;
}

.preview-image {
  width: 100%;
  height: 8rem;
  object-fit: cover;
  border-radius: 0.5rem;
}

.remove-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: #ef4444;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s ease;
}

.remove-button:hover {
  background-color: #dc2626;
}

.upload-button {
  width: 100%;
  background-color: #3b82f6;
  color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;
}

.upload-button:hover {
  background-color: #2563eb;
}

.upload-form-error {
  color: #ef4444;
  font-size: 0.875rem;
}