.userpage-template-null{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 300px;
  background-color: #F6F8FA;
  min-height: 100vh;
}

.userpage-template{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6F8FA;
  width: 100%;
  gap: 0px;
}
.userpage-template.top{
  background-color: #F6F8FA;
}
.userpage-template.bottom{
  background-color: white;
  padding-bottom: 50px;
}

.userpage-template-top-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: #F6F8FA;
  gap: 10px;
}
.userpage-template-top-container.pc{
  width: 750px;
}


.userpage-template-user-icon-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  width: 100%;
  min-height: 40px;
  position: relative;
  top: -25px;
}
.userpage-template-user-icon-wrapper.sp{
  top: -20px;
}

.userpage-template-user-icon{
  border: 2px solid white;
}

.userpage-template-user-info-and-numbers{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 10px;
}

.userpage-template-user-info{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.userpage-template-user-info.pc{
  width: 50%;
}

.userpage-template-bottom-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 60vh;
  background-color: white;
  gap: 10px;
  padding-bottom: 50px;
}
.userpage-template-bottom-container.pc{
  width: 750px;
}

.userpage-template-bottom-container-main{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  background-color: white;
  gap: 10px;
}
.userpage-template-bottom-container-main.pc{
  width: 100%;
}

.userpage-template-titles{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.userpage-template-titles.pc{
  margin-top: 40px;
}

.userpage-template-main{
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 65%;
  margin-top: 40px;
}

.userpage-template-main.sp{
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 0px;
  background-color: white;
}

.userpage-template-titles{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  width: 100%;
}