.sakairi-idea-card-molecules-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  position: relative;
  padding-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* スムーズな拡大アニメーション */
}

.sakairi-idea-card-molecules-container.hover-effect{
  margin: 10px auto;
}

.sakairi-idea-card-molecules-container.hover-effect:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    transform: scale(1.04);
}

.sakairi-idea-card-molecules-main{
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  width: 100%;
  pointer-events: none;
  gap: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
}

.sakairi-idea-card-molecules-texts{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
}

.sakairi-idea-card-molecules-descripiton{
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 5px;
}

.sakairi-idea-card-molecules-circle{
  font-size: 20px;
  color: #40B2D3;
  display: flex;
  align-items: flex-start;
  line-height: 1;
}