
.round-text-ions {
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
}

.round-require-text-ions {
  background-color: #383E86;
}
.round-optional-text-ions {
  background-color: grey;
}

.round-text-ions.tiny .round-text-ions.extra-small .round-text-ions.small {
  height: 20px;
  width: 50px;
  font-size: 12px;
}

.round-text-ions.normal {
  height: 23px;
  width: 55px;
  font-size: 14px;
}

.round-text-ions.medium .round-text-ions.large .round-text-ions.bit-large .round-text-ions.very-large .round-text-ions.extra-large {
  height: 26px;
  width: 60px;
  font-size: 16px;
}

