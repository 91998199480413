.idea-footer-wrapeer {
  position: fixed;
  z-index: 10;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  gap: 20px;
  background-color: white;
}

.idea-footer-heart-buttons{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}